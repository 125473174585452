/*= ========================================================================================
  Section name: Administration
  Inner routes: User managment
========================================================================================== */
export default {
  path: "/users-managment",
  name: "users-managment",
  meta: {
    menu_name: "Users managment",
    menu_section: "administration",
    icon: "mdi-account-multiple-plus",
    admin_required: false,
  },
  component: () => import("@/views/main_page/users_manager/users_manager.vue"),
};
